@if (asset(); as asset) {
  <a
  class="elevation-5 AssetCard"
  (touchstart)="onTouchStart()"
  (mouseenter)="onMouseEnter()"
  (mouseleave)="onMouseLeave()"
  (click)="comparisonMode() && asset.images && asset.images.length ? openLightbox() : cardClick.emit(asset.equipment_number)"
  attr.aria-label="{{asset.year}} {{asset.make}} {{asset.model}}"
  attr.qa-updated="{{ getUpdateDate() | date:'shortDate' }}"
  attr.qa-added="{{ getListingDate() | date:'shortDate' }}"
  data-testid="asset-card"
>
  <div class="AssetCard__image">
    <div class="AssetCard__badges">
      @if (badgesConfig(); as badges) {
        <app-badges-container
          [enabled]="badges.enabled"
          [labelType]="badges.data.labelType ?? 'reversed'"
          [labelModifier]="badges.data.labelModifier ?? 'secondary'"
          [asset]="asset"
        ></app-badges-container>
      }
    </div>
    @if (!comparisonMode()) {
      <div class="AssetCard__compare-toggle" [style.display]="!controlsActive ? 'none' : 'block'">
        <app-comparison-toggle
          [asset]="asset"
          [theme]="'with-bg'"
        />
      </div>
    }
    @if (asset.images && asset.images.length) {
      @if (showCarousel() && asset.images.length > 1) {
        <swiper-container
          #carouselWrapper
          init="false"
          data-testid="swiper-container"
          >
          @for (img of asset.images; track $index) {
            <swiper-slide data-testid="swiper-slide">
              <img [legacyImageProcessor]="{
                guid: img,
                transparent: false,
                type: LegacyImageServiceTypes.CarouselItem,
              }"
                [alt]="asset.year + ' ' + asset.make + ' ' + asset.model + ' image: #' + ($index + 1)"
                loading="lazy"
              />
              <div class="swiper-lazy-preloader"></div>
            </swiper-slide>
          }
        </swiper-container>
        <app-icon-button
          icon-btn-small
          icon-btn-icon-medium
          icon-btn-black
          class="AssetCard__carousel-nav-item AssetCard__carousel-nav-item--prev"
          [attr.data-prev]="'prev-'+asset.equipment_number"
          matIcon="navigate_before"
        ></app-icon-button>
        <app-icon-button
          icon-btn-small
          icon-btn-icon-medium
          icon-btn-black
          class="AssetCard__carousel-nav-item AssetCard__carousel-nav-item--next"
          [attr.data-next]="'next-'+asset.equipment_number"
          matIcon="navigate_next"
        ></app-icon-button>
      } @else {
        @if (asset && asset.images.length) {
          <img
            [legacyImageProcessor]="{
              guid: asset.images,
              transparent: false,
              type: LegacyImageServiceTypes.CarouselItem,
            }"
            [alt]="asset.year + ' ' + asset.make + ' ' + asset.model"
            data-testid="image"
            loading="lazy"
          />
          @if (comparisonMode()) {
            <div class="AssetCard__dots">
              @for (img of asset.images; track $index) {
                <div
                  class="AssetCard__dot"
                  [class.AssetCard__dot--active]="$index === 0"
                ></div>
              }
            </div>
            <div>
              <app-icon-button
                icon-btn-small
                icon-btn-icon-medium
                icon-btn-black
                class="AssetCard__carousel-nav-item AssetCard__carousel-nav-item--next"
                matIcon="navigate_next"
              ></app-icon-button>
            </div>
          }
        }
      }
    } @else {
      <img [src]="defaultImage()" alt="No photo available" data-testid="image" loading="lazy"/>
    }
  </div>
  <div class="AssetCard__details">
    @if (!comparisonMode()) {
      <div class="overline AssetCard__details--info1">
        @if (!!asset.branch_city) {
          <span>{{asset.branch_city}}</span>
        }
        @if (!!asset.branch_city && !!asset.branch_state) {
          <span>, </span>
        }
        @if (!!asset.branch_state) {
          <span>{{asset.branch_state}}</span>
        }
        @if ((!!asset.branch_city || !!asset.branch_state) && (!!asset.meter_hours || !!asset.meter_miles_precise)) {
          <span> | </span>
        }
        @if (!!asset.meter_hours || !!asset.meter_miles_precise) {
          <span data-testid="meter">{{asset | assetMeter : 'short'}}</span>
        }
      </div>
    }
    <div class="subtitle-1-medium AssetCard__details--info2">
      {{asset.year}} {{asset.make}} {{asset.model}}
    </div>
    <div
      [class]="{ 'AssetCard__details--footer-compare': comparisonMode(), 'AssetCard__details--footer': true }"
    >
      <div [style.display]="controlsActive && !comparisonMode() ? 'none' : 'flex'" class="h6 price" data-testid="price">
        @if (asset.list_price && !asset.is_list_price_hidden) {
          {{asset | assetPrice : currencyCode()}}
        } @else {
          <span i18n="@@detailsInformation_PriceNotAvailableText">Contact for Pricing</span>
        }
      </div>
      <div
        class="controls"
        [style.display]="!controlsActive ? 'none' : 'flex'"
        data-testid="controls">
        <app-button
          btn-small
          btn-outlined
          data-testid="view-details-button"
          (click)="viewDetailsClicked($event, asset)"
          i18n="@@assetCard_ViewDetailsButtonLabel"
        >View Details</app-button>
        <app-button
          btn-small
          btn-contained
          (click)="contactClicked($event, asset)"
          data-testid="contact-button"
          i18n="@@assetCard_ContactButtonLabel"
        >Contact</app-button>
      </div>
    </div>
  </div>
</a>
} @else {
  <div class="elevation-5 AssetCard AssetCard--loading">
    <div class="loading" data-testid="loading">
      <mat-spinner diameter="25"></mat-spinner>
    </div>
  </div>
}
@if (comparisonMode() && asset()?.images?.length) {
  <div *galleryBoxDef="let state = state; let config = config" class="g-box-info" data-testid="lightbox">
    <h6 class="lightbox-title h6">
      {{asset()?.year}} {{asset()?.make}} {{asset()?.model}}
    </h6>
  </div>
}
