import { CommonModule } from '@angular/common'
import { FormsModule } from '@angular/forms'
import { Component, inject, input, model, OnInit } from '@angular/core'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { filter } from 'rxjs'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { ChipsService } from 'src/app/core/services/chips.service'
import { EventService } from 'src/app/core/services/event.service'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { MatSelectChange, MatSelectModule } from '@angular/material/select'
import { IJsonLogic } from 'src/app/core/interfaces/json-logic.interface'
import { FilterId } from 'src/app/core/interfaces/filter.interface'
import { IZipCodeFilterState } from 'src/app/core/interfaces/zip-code-filter.interface'
import { jsonLogic } from 'src/app/core/utils/json-logic.util'


export function getEmptyZipCodeFilterState(): IZipCodeFilterState {
  return {
    type: 'zipcode',
    value: null,
    miles: 500,
  }
}

export const checkZipCodeFilterEmpty = (state: IZipCodeFilterState) => !state.value

@Component({
  selector: 'app-zip-code-filter',
  templateUrl: './zip-code-filter.component.html',
  styleUrls: ['./zip-code-filter.component.scss'],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
  ],
})
export class ZipCodeFilterComponent implements OnInit {
  private _chipsService = inject(ChipsService)
  private _eventService = inject(EventService)

  filterId = input.required<FilterId>()
  state = model.required<IZipCodeFilterState>()

  constructor() {
    this._eventService.chipRemoved$.pipe(
      takeUntilDestroyed(),
      filter(chip => chip.filterId === this.filterId()),
    ).subscribe(() => this.clearAll())

    this._eventService.allChipsRemoved$.pipe(
      takeUntilDestroyed(),
    ).subscribe(() => this.clearAll())
  }

  ngOnInit(): void {
    this.processStateChange()
  }

  clearAll() {
    this.state.set(getEmptyZipCodeFilterState())
    this._chipsService.removeChip(this.filterId())
  }

  processGeoLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(position => {
        this.state.update((state) => {
          state.type = 'latlong'
          state.value = [position.coords.latitude, position.coords.longitude]
          return { ...state }
        })
        this.processStateChange()
      })
    }
  }

  processStateChange(): void {
    this._chipsService.removeChip(this.filterId())
    if (!checkZipCodeFilterEmpty(this.state())) {
      if (this.state().type === 'latlong') {
        this._chipsService.addChip({
          id: this.filterId(),
          label: `My location - ${this.state().miles} miles`,
          filterId: this.filterId(),
        })
      } else {
        this._chipsService.addChip({
          id: this.filterId(),
          label: `${this.state().value} - ${this.state().miles} miles`,
          filterId: this.filterId(),
        })
      }
    }
  }

  onZipCodeChanged($event: Event): void {
    const target = $event.target as HTMLInputElement
    if (target.checkValidity() || !!target.value?.trim()) {
      this.state.update((state) => ({
        ...state,
        type: 'zipcode',
        value: target.value?.trim()
      }))
      this.processStateChange()
    }
  }

  onMilesChanged(change: MatSelectChange): void {
    this.state.update((state) => ({
      ...state,
      miles: change.value
    }))
    this.processStateChange()
  }

  static buildFilter(
    state: IZipCodeFilterState,
    field: string,
  ): IJsonLogic {
    const { miles } = state
    const { variable, lte } = jsonLogic
    return lte(variable(field), miles)
  }

  static buildQueryParams(
    state: IZipCodeFilterState
  ): Record<string, string> {
    const { type, value } = state
    return type === 'latlong' ? { lat: value[0], long: value[1] } : { postal_code: value }
  }
}
