import { CUSTOM_ELEMENTS_SCHEMA, Component, ElementRef, OnInit, ViewChild, inject, input, output } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatSelectModule } from '@angular/material/select'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { AssetPricePipe } from 'src/app/core/pipes/asset-price.pipe'
import { LegacyImageProcessorDirective } from 'src/app/core/directives/legacy-image-processor.directive'
import { BadgesContainerComponent } from '../badge-container/badges-container.component'
import { IAsset } from 'src/app/core/interfaces/asset.interface'
import { ButtonModule } from '../button/button.module'
import { AssetMeterPipe } from '../../core/pipes/asset-meter.pipe'
import { IconButtonModule } from '../icon-button/icon-button.module'
import { IComponentConfig } from 'src/app/core/interfaces/config.interface'
import { ToDateObjPipe } from 'src/app/core/pipes/to-date-obj.pipe'
import { LegacyImageProcessorTypes } from 'src/app/core/interfaces/image.interface'
import { CurrencyCode } from 'src/app/core/interfaces/currency.interface'
import { GalleryModule, Gallery, GalleryItem, ImageItem, GalleryBoxDef } from 'ng-gallery'
import { Lightbox } from 'ng-gallery/lightbox'
import { ImageService } from 'src/app/core/services/image.service'
import { LogService } from 'src/app/core/services/log.service'
import { GlobalDataService } from 'src/app/core/services/global-data.service'
import { ImageSizes } from 'src/app/core/interfaces/media-library.interface'
import { ComparisonToggleComponent } from '../comparison-toggle/comparison-toggle.component'


@Component({
  selector: 'app-asset-card',
  templateUrl: './asset-card.component.html',
  styleUrls: ['./asset-card.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    LegacyImageProcessorDirective,
    BadgesContainerComponent,
    AssetPricePipe,
    ButtonModule,
    IconButtonModule,
    AssetMeterPipe,
    GalleryModule,
    ComparisonToggleComponent,
  ],
})
export class AssetCardComponent implements OnInit {

  @ViewChild('carouselWrapper') carouselWrapper!: ElementRef
  @ViewChild(GalleryBoxDef) lightBoxTitle!: GalleryBoxDef

  _logger = inject(LogService)
  globalDataService = inject(GlobalDataService)
  lightbox = inject(Lightbox)
  gallery = inject(Gallery)
  imageService = inject(ImageService)
  asset = input<IAsset>()
  showCarousel = input(false)
  currencyCode = input<CurrencyCode>(CurrencyCode.USD)
  defaultImage = input<string>('')
  badgesConfig = input<IComponentConfig>()
  comparisonMode = input<boolean>(false)
  cardClick = output<string>()
  contactClick = output<IAsset>()

  LegacyImageServiceTypes = LegacyImageProcessorTypes
  controlsActive: boolean = false
  isTouch: boolean = false
  toDatePipe = new ToDateObjPipe()
  galleryId = ''

  async ngOnInit(): Promise<void> {
    await new Promise<void>(resolve => {
      setTimeout(() => {
        this.swiperInit()
        if (this.comparisonMode()) {
          this.controlsActive = true
          this.galleryId = `lightbox-${this.asset()?.equipment_number}`
          this.lightboxInit()
        }
        resolve()
      }, 100)
    })
  }

  async lightboxInit() {
    const items: GalleryItem[] = []
    for (const guid of (this.asset()?.images ?? [])) {
      let src = this.imageService.buildLegacyProcessorSrc({ guid, type: LegacyImageProcessorTypes.CarouselItem, transparent: true })
      const logo = this.globalDataService.config().header?.data?.logo?.src_data?.urls?.[ImageSizes.Thumbnail]

      if (logo && this.globalDataService.config().general?.data?.watermark_logo) {
        src = await this.imageService.applyWatermark(src, logo)
      }
      items.push(
        new ImageItem({
          src,
          thumb: this.imageService.buildLegacyProcessorSrc({ guid, type: LegacyImageProcessorTypes.FeaturedThumb, transparent: true }),
        })
      )
    }

    this.gallery.ref(this.galleryId).setConfig({
      counter: false,
      loadingStrategy: 'preload',
      imageSize: 'cover',
      boxTemplate: this.lightBoxTitle.templateRef,
    })
    this.gallery.ref(this.galleryId).load(items)
  }

  openLightbox() {
    this._logger.debug('open lightbox', this.galleryId)
    this.lightbox.open(0, this.galleryId)
  }

  viewDetailsClicked(event: Event, asset: IAsset): void {
    event.preventDefault()
    event.stopPropagation()
    this.cardClick.emit(asset.equipment_number)
  }

  contactClicked(event: Event, asset: IAsset): void {
    event.preventDefault()
    event.stopPropagation()
    this.contactClick.emit(asset)
  }

  onTouchStart(): void {
    this.isTouch = true
  }

  onMouseEnter(): void {
    if (this.comparisonMode()) { return }
    if (!this.isTouch) {
      this.controlsActive = true
    }
  }

  onMouseLeave(): void {
    if (this.comparisonMode()) { return }
    this.controlsActive = false
  }

  getListingDate(): Date | null {
    return this.asset()?.listing_date ?
      this.toDatePipe.transform(this.asset()?.listing_date)
      : new Date()
  }

  getUpdateDate(): Date | null {
    return this.asset()?.updated_date ?
      this.toDatePipe.transform(this.asset()?.updated_date)
      : new Date()
  }

  swiperInit() {
    if (!this.showCarousel || !this.asset) {
      return
    }

    const carouselOptions = {
      pagination: {
        enabled: true,
      },
      preventClicksPropagation: true,
      touchMoveStopPropagation: true,
      navigation: {
        nextEl: `[data-next="next-${this.asset()?.equipment_number}"]`,
        prevEl: `[data-prev="prev-${this.asset()?.equipment_number}"]`,
      },
    }

    if (this.carouselWrapper?.nativeElement) {
      Object.assign(this.carouselWrapper.nativeElement, carouselOptions)
      this.carouselWrapper.nativeElement.initialize()

      // prevent click propagation to asset card on next/prev buttons
      const stopClickEventListener = (event: Event) => {
        event.stopPropagation()
      }
      const nextButton = document.querySelector(`[data-next="next-${this.asset()?.equipment_number}"]`)
      const prevButton = document.querySelector(`[data-prev="prev-${this.asset()?.equipment_number}"]`)
      nextButton?.removeEventListener('click', stopClickEventListener)
      prevButton?.removeEventListener('click', stopClickEventListener)
      nextButton?.addEventListener('click', stopClickEventListener)
      prevButton?.addEventListener('click', stopClickEventListener)
    }
  }
}
